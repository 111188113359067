import { useEffect, useState,useRef } from "react";
import React from 'react';
import {Box,Grid,Collapse,} from '@mui/material';
import ChipContract from '../../components/MUI/ChipContract.js';
import PrettoSlider from '../../components/MUI/SliderPetto.js';
import AlertNoMetaMask from "../../components/MUI/AlertNoMetamask";
import AlertContract from "../../components/MUI/AlertContract";
import detectEthereumProvider from '@metamask/detect-provider';


import MetaMaskStatus, {
   getCurrentWalletConnected,
   getNbOfOwnedToken, 
   ReadBC,
   mint,claimToken,
   connectWallet
 } from "../../utils/interact.js";
import MetamaskStatus from "../../utils/interact.js";
import SaleButton from "./SaleMainButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Paper } from "@mui/material";

import './MintSection.css';




const structureImg = "images/mintsection/Mint_Structure.png";
const fishScreen = "images/mintsection/Mint_FishScreen.gif";
const mintScreenImg = "images/mintsection/Mint_MintScreen.png";
const mintScreenBgImg = "images/mintsection/Mint_MintScreenBg.png";
const moriesScreenImg = "images/mintsection/Mint_MoriesScreen.png";
const logoBlackImg = "images/deco/LogoNoir.png";

const smallPoleImg = "images/allsection/PoteauPetitSite.png";
const bigPoleImg = "images/allsection/PoteauGrandSite.png";


function MintSection({
  sectionId,
  headline,
  subheadline,subheadline2,
  presentationTxt,
  openseaURL,
  token2MintDefault,
  tokenPrice,
  showContract,
  soldOut,
  fishes,
  alerts,
  specifs
}) {



/* #region state variable */

const [walletAddress, setWallet] = useState("");
const [status, setStatus] = useState("");
const [curChainId, setChainId] = useState("");
const [maxSupply, setMaxSupply] = useState("");
const [token2Mint, setToken2Mint] = useState(token2MintDefault);
const [ nbOfOwnSSF,setNbOfOwnedSSF ] = useState(0);
const [ nbOfOwnSSFU,setNbOfOwnedSSFU ] = useState(0);
const [ nbOfClaimable,setNbOfClaimable ] = useState(0);

const [checked, setChecked] = useState(false);
const containerRef = useRef(null);
const [transitionDone, setTransitionDone] = useState(false);



const importedWiteList= window['wl'];
const [whitelist,SetWhiteList] = useState([]);

/* #endregion */



useEffect(async () => {

  
  const provider = await detectEthereumProvider();
  
  if (provider) {
    
      var {CHAIN_ID} =  window['runConfig'];

      const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
      setChainId(_chainId);
      if (_chainId != CHAIN_ID) { 
          setStatus(MetaMaskStatus.WRONG_NETWORK);
      } else {
          const walletResponse = await getCurrentWalletConnected();
          setStatus(walletResponse.status);
          setWallet(walletResponse.address);
      }
      addChainListener();
      addWalletListener();

    } else {
      setStatus(MetaMaskStatus.NO_METAMASK);
}


}, [status]);;

/* #region MetamaskManagement */

const  connectToMetamask =  async () => {
  
  if (window.ethereum) {
      const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
      setChainId(_chainId);
     if (_chainId != CHAIN_ID) {
          setStatus(MetaMaskStatus.WRONG_NETWORK);
          alertMetamask();
     } else {
      const walletResponse = await connectWallet();
      setStatus(walletResponse.status);
      setWallet(walletResponse.address);

     }
  }else {
    setStatus(MetaMaskStatus.NO_METAMASK);
    AlertNoMetaMask()

  }
}



function addChainListener() {
  if (window.ethereum) {
    window.ethereum.on("chainChanged", (_chainId) => {
        setChainId(_chainId);
        setStatus(MetaMaskStatus.ACCOUNT_NOT_CONNECTED);
        if (_chainId != CHAIN_ID) {
          setStatus(MetaMaskStatus.WRONG_NETWORK);
        }       
      });  
  }
}



function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        /*
        setWallet(accounts[0]);
        refreshContract();*/
        setWallet("");
        setStatus(MetaMaskStatus.ACCOUNT_NOT_CONNECTED);
      } else {
        setWallet("");
        setStatus(MetaMaskStatus.ACCOUNT_NOT_CONNECTED);
      }
    });
  } 
}



const {contractAddress,abiFileName,CHAIN_ID} =  window['runConfig'];
const contractABI = require('../../abi/' + abiFileName).abi;

/* #endregion */


const refreshContract = async () => {
    if (status==MetaMaskStatus.ACCOUNT_CONNECTED) {
      /*   
      const {ownedToken} = await getNbOfOwnedToken(contractABI,contractAddress);
        setNbOfOwnedToken(ownedToken);*/
        const _retval = await ReadBC(contractABI,contractAddress,'dualBalanceOf',[walletAddress]) 
        setNbOfOwnedSSFU(_retval.SSFU_Bal);
        setNbOfOwnedSSF(_retval.SSF_Bal);
        setNbOfClaimable(_retval.SSFU_Claimable);
        const _maxSupply = await ReadBC(contractABI,contractAddress,'totalSupply',[]) 
        setMaxSupply(_maxSupply); 
    }
    return "";
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  

  const mainButtonPressed = async () => {
    if(specifs.phaseStatus =='aftersale') {
        openInNewTab(specifs.openseaURL);
    } else {
        if ((curChainId != CHAIN_ID) && (status != MetaMaskStatus.NO_METAMASK)) {
          setStatus(MetaMaskStatus.WRONG_NETWORK);
         // alert(curChainId);
          alertMetamask();
        } else {
          if (status == MetaMaskStatus.ACCOUNT_CONNECTED) {
            if (specifs.phaseStatus =='presale') {
                if (nbOfClaimable> 0) {
                const { success,status } = await claimToken(contractABI,contractAddress,token2Mint);
                if (! success) {alert(status)} else  {
                  alertContract(12);
                  const ret = await refreshContract();
                } 
              }else {
                  alertContract(13);
                }
              
            }else {
                const { success,status } = await mint(contractABI,contractAddress,token2Mint);

            if (! success) {alert(status)} else  {
            alertContract(12);
            const ret = await refreshContract();
            }            
          }
          } else {
            alertMetamask();
          }
        }
    }
  };

  const onSliderChange = (event, newValue) => {
    setToken2Mint(newValue);
  };

  const setSliderMax = () =>{
    if (specifs.phaseStatus =='presale') return nbOfClaimable;
    if (specifs.phaseStatus =='sale') return specifs.maxTokenToMint;
    return 0;

  }

  const setOwnerCounterText = () =>{
    if (specifs.phaseStatus =='presale') return "owned SSFU : "+ nbOfOwnSSFU + "   (to claim :" + nbOfClaimable  + " )";
    if (specifs.phaseStatus =='sale') return "owned SSFU : "+ nbOfOwnSSFU;
    return "";

  }


/* #region Alert Metamask management   */

  const [open, setOpen] = useState(false);

  const alertMetamask = () => {
    setOpen(true);
   }

  const handleClose = async () => {
    setOpen(false);
  };

  const signIn = async () => {
    setOpen(false);
    if (status==MetaMaskStatus.ACCOUNT_NOT_CONNECTED) {
      const walletResponse = await connectWallet();
      setStatus(walletResponse.status);
      setWallet(walletResponse.address);
      const ret = await refreshContract();
    }
  };

  /* #endregion */

/* #region Alert management  */

  const [openContractAlert, setOpenContractAlert] = useState(false);
  const [contractAlertTitle, setContractAlertTitle] = useState(false);
  const [contractAlertText, setContractAlertText] = useState(false);

  const alertContract = (alertNb) => {
    setContractAlertTitle(alerts[alertNb].title);
    setContractAlertText(alerts[alertNb].corpse);
    setOpenContractAlert(true);
   }

   const alertContractError = (alertTxt) => {
    setContractAlertTitle("Error on blockchain");
    setContractAlertText(alertTxt);
    setOpenContractAlert(true);
   }
  
  
  
   const handleCloseContractAlert = async () => {
    setOpenContractAlert(false);
  };

/* #endregion */



const renderImgTop = () => {
  if (true) {
    return <img className='mint-top-img'  src={fishes[5]}  />;
  }
}

const renderImgBot = () => {
  if (true) {
    return <img className='mint-bot-img'  src={fishes[2]}  />;
  }
}

const onCoverClicked = () =>{
  if (!checked){ 
    setChecked(true);
    if (status == MetaMaskStatus.ACCOUNT_CONNECTED) {refreshContract()}

  }
}






  return (
    <>
      <Box  display="flex"  justifyContent="center"   id={sectionId} sx={{mt:'150px'}}  >
       <Grid container  className={'topcontainer'}    sx={{paddingTop:5 }}   justifyContent="center"  >
        <Grid item>
            <Box className={'relativecontainer'} >
                       
                       
                       {/*
                        <Box className={'nopointer noselect'} >
                              <img  src={panneauHImg}></img>
                        </Box>
                       */}

                        
                        {/* Image cadre */}
                        <Box className={'nopointer noselect'} >
                              <img  src={structureImg}></img>
                        </Box>


                         {/*  Panneau Presentation */ }  
                         <Box  className={'mint-presentationbox noselect'} display="flex" alignContent="center" >
                            <Grid container justifyContent="center" display="flex" alignContent="center"
                                  alignItems="center"  paddingRight='3px'  >
                                <Grid item xs={4}>  
                                    <Box >
                                    <img  src={logoBlackImg}></img>
                                    </Box>
                                </Grid>
                                <Grid item xs={8}>  
                                <Box className="mint-presentation">{presentationTxt}</Box>
                                </Grid>
                            </Grid>
                        </Box>                       

                        <Box >
                              <button className={'mint-presentationbtn'} onClick={a=>alertContract(7)} ></button>
                        </Box> 


                        {/*  Panneau Fish Gif */ }
                        <Box className={'mint-fishbox nopointer noselect'} >
                              <img  src={fishScreen}  ></img>
                        </Box>

                        <Box >
                              <button className={'mint-fishboxbtn'} onClick={a=>alertContract(8)} ></button>
                        </Box> 

                        {/*  Panneau Mint */ }
                        <Box className={'mint-paperbox'} display="flex" justifyContent="center"  alignContent="center">

                            <Box className={'mint-mintpaper'}  >
                                <Grid  container   direction="column"    alignContent="center" rowSpacing={{xs: 0, mob1:0,mob2:1,mob3:2, sm: 4}} >

                                        {/* Text Block  -------------------- */}
                                        <Grid item  paddingLeft={{ xs:'3px', mob1: '15px', mob2: '25px',sm:'25px' }} marginTop={{ xs:'2px',mob1: '5px', mob2: '10px',sm:'10px' }} width="100%"> 

                                              <Box  className={'mint-heading noselect'} display="flex"  alignContent="flex-start" >
                                                  {specifs.headline}
                                              </Box>

                                              <Box  className={'mint-subheading noselect'}
                                              display="flex" justifyContent="flex-start"  alignContent="flex-star" >
                                                  {specifs.subheadline}
                                              </Box>
                                              <Box  className={'mint-subheading2 noselect'}
                                              display="flex" justifyContent="flex-start"  alignContent="flex-star" >
                                                  {specifs.subheadline2}
                                              </Box>

                                        </Grid>

                                        {/* Main block with slider, buttton and price  -------------------- */}
                                        <Grid item xs={12} paddingTop="0px" marginTop={{ xs:'2px', sm: '7px' }}> 
                                            <Grid container justifyContent="center" direction="column"  alignItems="center">
                                              <Grid item className={'mintGroup'} >
                                                { (status ==MetamaskStatus.ACCOUNT_CONNECTED) && ((specifs.phaseStatus=='sale') || (specifs.phaseStatus=='presale') ) ? 
                                                  <PrettoSlider   
                                                          display='none'
                                                          defaultValue={token2MintDefault}
                                                          valueLabelDisplay="auto"
                                                          tep={1}
                                                          min={1}
                                                          max={setSliderMax()}
                                                          onChange={onSliderChange}
                                                      />
                                                      :<></>
                                                }
                                              </Grid>
                                              { (specifs.phaseStatus !='beforesale') &&
                                              <Grid item >
                                                    <SaleButton status={status} onClick={mainButtonPressed}  token2Mint={token2Mint}   phaseStatus={specifs.phaseStatus}   ></SaleButton>
                                              </Grid>
                                                }

                                            </Grid>  
                                        </Grid>

                                        {/* Owned cm  and contract */}    
                                        <Grid item xs={12} > 
                                            <Grid container  justifyContent="space-around" direction="row"  alignItems="center" >
                                              
                                              {/* Owned cm */}    
                                              { ((status ==MetamaskStatus.ACCOUNT_CONNECTED) &&((specifs.phaseStatus=='sale') || (specifs.phaseStatus=='presale') ) )   &&  
                                              <Grid item >
                                               
                                                  <Box  display="flex" justifyContent="center" alignContent="center" alignItems="center">
                                                        <Box  className={'mint-subheading2'}>{setOwnerCounterText()}</Box>
                                                        <IconButton  onClick={refreshContract} size="small" ><RefreshIcon color='primary'  /> </IconButton>   
                                                  </Box> 

                                              </Grid>
                                               }
                                               {/* Etherscan */}   
                                                
                                              {(specifs.showContract) &&  
                                              <Grid item > 
                                                  <Grid container  justifyContent="center" direction="column"  alignItems="center" >
                                                      <Grid item >    
                                                          {/* <Box display="flex" alignItems="center" justifyContent="center" className={'mint-subheading2'}   > Etherscan</Box> */}
                                                          <ChipContract contractAddress={contractAddress}  />
                                                
                                                      </Grid>
                                                  </Grid>     
                                              </Grid>          
                                              }
                                            
                                            
                                            </Grid>
                                        </Grid> 
                                                
                                        



                                </Grid>  
                                

                         
                            </Box>
     
                        </Box> 
                  
                        { (!transitionDone) && 
                        <Box className={'mint-mintcoverbox'}  onClick={a=>onCoverClicked()} >
                        
                          <Collapse orientation="vertical" in={!checked}  
                               {...(checked ? { timeout: 3000 } : {})}
                               onExited={a=>setTransitionDone(true)}>
                               <Box  className={'noselect'}>
                               <img  src={mintScreenImg}  ></img>
                               </Box>
                          </Collapse>                               
                         </Box> 
                        }

                        <Box className={'mint-mintbgbox noselect'} >
                                  <img  src={mintScreenBgImg}  ></img>
                        </Box>  
                        
                         {/*  Panneau Mories */ }
                        <Box className={'mint-moriesbox noselect '} >
                              <img  src={moriesScreenImg}  ></img>
                        </Box>

                        <Box >
                              <button className={'mint-moriesbtn'} onClick={a=>alertContract(0)} ></button>
                        </Box>  

                         {/*  Panneau Price */ }  
                        <Box  className={'mint-pricebox noselect'} >
                            <Grid container direction="column" justifyContent="center" alignContent="center"
                                  alignItems="center"  paddingTop='40%'  >
                                  
                                  <Grid item>{(tokenPrice * token2Mint ).toFixed(2)} </Grid>
                                  <Grid item>ETH</Grid>

                            </Grid>
                        </Box>                       

                        <Box >
                              <button className={'mint-pricebtn'} onClick={a=>alertContract(1)} ></button>
                        </Box> 


            </Box>

        </Grid>
        
        
      {/*  Ajout de poteux */}  
       
       {(false) && 
        <Grid item  className={'mint-container'} >
                <Box>
                      <img  src={bigPoleImg} />
                </Box> 
                <Box className={'mint-fihspole1img'} >
                                  <img  src='images/F2_Focus.png'  width="100%" ></img>
                        </Box> 
          </Grid>  
      }

  
  
      </Grid>
      </Box> 

      <AlertNoMetaMask open={open} handleClose={handleClose} status={status} signIn={signIn}  />
      <AlertContract open={openContractAlert} handleClose={handleCloseContractAlert} title={contractAlertTitle} corpse={contractAlertText} />

    </>
  );
}

export default MintSection;