import * as React from 'react';
import { styled } from '@mui/material/styles';
import {Box,Button,Grid} from '@mui/material';
import {Dialog,DialogActions,DialogContent,DialogTitle,DialogContentText} from '@mui/material';



import MetaMaskStatus from "../../utils/interact.js";
import { blueGrey } from '@mui/material/colors';






const btnDisplay =(props)=>{
  return   <Button sx={{marginRight:'auto',marginLeft :'auto',marginBottom:'25px' }}  
  variant="contained" onClick={props.handleClose}>UNDERSTOOD</Button>
}






export default function AlertContract(props) {
  return (
    <Dialog 
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ overflow:'hidden'}}
        PaperProps={{
          style: {
           // backgroundColor: '#fbf0c3', 
            maskBorderWidth: '5px'
          },
        }}
        disableScrollLock={true}
      >
  <DialogTitle id="alert-dialog-title" sx={{pl:'10px'}}   >
    <Grid container  alignItems="center" display="flex" alignContent="flex-start" justifyContent="flex-start">
    <Grid item>
        <Box >
          <img width='60px' height='60px' src='images/deco/alerticone.png'  />
        </Box>
      </Grid> 
      <Grid item>
        <Box  maxWidth='280px'  textAlign='center'  sx={{fontSize:'20px',fontWeight:600}} >{props.title}</Box>
      </Grid>  
   </Grid>      
  </DialogTitle>
  <DialogContent sx={{marginRight:'auto',marginLeft :'auto',marginBottom:'20px' ,
        marginTop:'0px',  minWidth:'310px',
        maxWidth:'400px',minHeight:'180px' }} >
        <Grid container  alignItems="center" display="flex" alignContent="space-around" justifyContent="space-around" direction="column"  >
     {(props.contentImg) &&
    <Grid item >
        <Box >
          <img width='120px' height='120px' src={props.contentImg}  />
        </Box>
      </Grid> 
      }
      <Grid item>
        <Box   textAlign='left'  sx={{fontSize:'16px'}} >{props.corpse}</Box>
      </Grid>  

   </Grid>    

</DialogContent>
<DialogActions>
    {btnDisplay(props)}
</DialogActions>
</Dialog>



  );
}

