import { useEffect, useState } from "react";
import './TeamSection.css';
import {Box,Grid,Collapse} from '@mui/material';
import {mod} from '../../utils/myLib';
import AlertContract from "../../components/MUI/AlertContract";


const structureImg = "images/teamsection/Team_Structure.png";
const drawingsImg = "images/teamsection/Team_Drawings.gif";
const inkImg = "images/teamsection/Team_Ink.png";
const teamLabelImg = "images/teamsection/Team_Label.png";
const memberArr = ["images/teamsection/Team_Member1.png",
                      "images/teamsection/Team_Member2.png",
                      "images/teamsection/Team_Member3.png",
                      "images/teamsection/Team_Member4.png",
                      "images/teamsection/Team_Member5.png"];
const memberBgImg = "images/teamsection/Team_Background.png";





function TeamSection({
  sectionId,
  fishes,
  alerts,
}) {

  useEffect(() => {
    //preloading image
    memberArr.forEach((member) => {
      const img = new Image();
      img.src = member;
    });
  }, []);
  




/* #region team loop */

  const transitionDuration = 300;

  const [activeIndex, setActiveIndex] = useState(0);
  const [elementIn, setElementIn] = useState(true);

  const onClick = (way) => {
    setElementIn(false);
      setTimeout(() => {
          setElementIn(true);
          setActiveIndex(mod(activeIndex + way, memberArr.length));
       }, transitionDuration);
    };

/* #endregion */


/* #region Alert management  */

const [openContractAlert, setOpenContractAlert] = useState(false);
const [contractAlertTitle, setContractAlertTitle] = useState(false);
const [contractAlertText, setContractAlertText] = useState(false);

const alertContract = (alertNb) => {
  setContractAlertTitle(alerts[alertNb].title);
  setContractAlertText(alerts[alertNb].corpse);
  setOpenContractAlert(true);

 }

const handleCloseContractAlert = async () => {
  setOpenContractAlert(false);
};

/* #endregion */


const renderImgTop = () => {
  if (true) {
    return <img className='spacedrive-top-img' alt=''  src={fishes[5]}  />;
  }
}

const renderImgBot = () => {
  if (true) {
    return <img className='spacedrive-bot-img' alt='' src={fishes[6]}  />;
  }
}
  
  


  return (
    <>
    <Box  display="flex"  justifyContent="center"   id={sectionId} >
        <Grid container  className={'topcontainer'}      justifyContent="center"  >
            <Grid item>
                <Box  className={'relativecontainer'} >
                            {/*  Poisson relataf */ }
                           { (false) &&
                            <Box>
                            {renderImgTop()}
                            {renderImgBot()}                  
                            </Box>
                            }
                            {/* Image cadre */}
                            <Box className='noselect' >
                              <img  src={structureImg} alt='' ></img>
                            </Box>
            
                            {/*  Panneau members */ }
                            <Box className={'team-membersbox team-below noselect'} >
                                    <img  src={memberBgImg} alt=''></img>  
                            </Box>  
                            <Box className={'team-membersbox team-below noselect'} >
                            <Collapse  in={elementIn} timeout={transitionDuration}  direction="right">
                                <Box   >
                                    <img  src={memberArr[activeIndex]} alt=''></img>  
                                </Box>
                            </Collapse>
                            </Box>  
                            <Box >
                              <button className={'team-membersbuttonbox'} onClick={a=>onClick(1)} ></button>
                            </Box>    
                            <Box >
                              <button className={'team-membersbuttonUp'} onClick={a=>onClick(1)} ></button>
                            </Box>  
                            <Box >
                              <button className={'team-membersbuttonDn'} onClick={a=>onClick(-1)} ></button>
                            </Box> 

                            {/*  Panneau drawings */ }  
                            <Box className={'team-drawingsbox noselect'} >
                                  <img  src={drawingsImg} alt=''></img>  
                            </Box>                           
                            <Box >
                              <button className={'team-drawingbtn'} onClick={a=>alertContract(10)} ></button>
                            </Box> 

                            {/*  Panneau inks */ }  
                            <Box className={'team-inkbox noselect'} >
                                  <img  src={inkImg} alt=''></img>  
                            </Box>    
                            <Box >
                              <button className={'team-inkbtn'} onClick={a=>alertContract(9)} ></button>
                            </Box>                            



                            {/*  Panneau label Team */ }  
                            <Box className={'team-labelbox'} >
                                  <img  src={teamLabelImg} alt=''></img>  
                            </Box>    
                            <Box >
                              <button className={'team-labelbtn'} onClick={a=>onClick(1)} ></button>
                            </Box> 


                </Box>

            </Grid>


        </Grid>
    </Box> 
    <AlertContract open={openContractAlert} handleClose={handleCloseContractAlert} title={contractAlertTitle} corpse={contractAlertText} />
    </>
  );
}

export default TeamSection;