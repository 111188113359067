import React from 'react';
import { useEffect, useState } from "react";
import LearnSection from '../../section/LearnSection/LearnSection';
import FAQSection from '../../section/FAQSection/FAQSection';
import SpaceDriveSection from '../../section/SpaceDriveSection/SpaceDriveSection';
import TeamSection from '../../section/TeamSection/TeamSection';
import RaffleSection from '../../section/RaffleSection/RaffleSection';
import MintSection from '../../section/MintSection/MintSection';
import {faq,roadmap,presale,sale,mint,raffle ,learn,spacedrive,team,} from './Home-Data';
import RoadMapSection from '../../section/RoadMapSection/RoadMapSection';


const  Home = (props) => {
  const [saleStatus, setSaleStatus] = useState("");
  const [persoStatus, setPersoStatus] = useState(false);
  
  
  useEffect(() => {
      var {saleStatus} =  window['runConfig']; 
    setSaleStatus(saleStatus);
    }
  , [])
  
  

  
  
  return (
    <>
      <LearnSection {...learn} />
      
      <MintSection {...mint}  /> 
    
      <SpaceDriveSection {...spacedrive} />     
      <TeamSection  {...team}  />    
      <FAQSection {...faq} /> 
      <RoadMapSection {...roadmap} />   
     {/*} <FAQSection {...roadmap} /> */}
    </>
  );
}

export default Home;
