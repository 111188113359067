import { useEffect, useState } from "react";
import React from 'react';
import {Box,Grid,Chip, Typography,Paper,Alert} from '@mui/material';

import { blue , yellow,green} from '@mui/material/colors';
import AlertNoMetaMask from "../../components/MUI/AlertNoMetamask";
import AlertContract from "../../components/MUI/AlertContract";
import detectEthereumProvider from '@metamask/detect-provider';
import {shortWallet,openInNewTab} from '../../utils/myLib';



import MetaMaskStatus, { getCurrentWalletConnected, connectWallet} from "../../utils/interact.js";

 import RaffleRegisterButton from "./RaffleRegisterButton";
 import DrawList from "./DrawList";

/******************************************************************************
 * 
 * RaffleSection
 * 
**************************************************************************** */
function RaffleSection({
  colorBg,
  lightText,
  sectionId,
  headline,
  subheadline,
  apologyLine,
  dividerColor,dividerAlpha,
  raffleCompleted,
  }) {

/* #region state variable */
const [walletAddress, setWallet] = useState("");
const [status, setStatus] = useState("");
const [curChainId, setChainId] = useState("");
const [draws, setDraws] = useState([]);
const [registered, setRegistered] = useState(false);
const [nbOfWin, setNbOfWin] = useState(0);
const [nbOfPlayers, setNbOfPlayers] = useState(0);
/* #endregion */

//const backendURL ="https://iwwonbackend.azurewebsites.net/cm";
const backendURL ="http://localhost:3001/cm";


/* #region useffct */

/*****************************
 * 
 * USE EFFECT : Check Metamask and Load drawsList
 * 
******************************** */
useEffect(async () => {

  const isRegistered = async(aWallet) =>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action:"isregister",callingaddr:aWallet })
    };
    
    const res = await  fetch(backendURL,requestOptions);
    const data = await res.json();
    console.log("data " + JSON.stringify(data));
    if (data.Error==0) {
      setRegistered(true); 
      setNbOfWin(data.nbOfWin);
      setNbOfPlayers(data.nbOfPlayers);
    }else {
      setRegistered(false);
    }
     console.log("isregistered useeffect " + JSON.stringify(data) );
   };//isregistered



  /*Metamask part */
  const provider = await detectEthereumProvider();
  
  if (provider) {
    
      var {CHAIN_ID} =  window['runConfig'];

      const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
      setChainId(_chainId);
      if (_chainId != CHAIN_ID) { 
          setStatus(MetaMaskStatus.WRONG_NETWORK);
      } else {
          const walletResponse = await getCurrentWalletConnected();
          setStatus(walletResponse.status);
          setWallet(walletResponse.address);
          if (walletResponse.address != '') isRegistered(walletResponse.address);
      }
      addChainListener();
      addWalletListener();

    } else {
      setStatus(MetaMaskStatus.NO_METAMASK);
}

/* backend */

const getDrawList = async() =>{
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ action:"drawlist" })
  };
  
  const res = await  fetch(backendURL,requestOptions);
  const data = await res.json();
  setDraws( draws => [...draws, data.Data]);
};

const getNbOfPlayers = async() =>{
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ action:"nbofplayers" })
  };
  
  const res = await  fetch(backendURL,requestOptions);
  const data = await res.json();
  setNbOfPlayers(data.nbOfPlayers);
};



getDrawList();
getNbOfPlayers();

}, []);;

/* #endregion */


//console.log("draws=" + JSON.stringify(draws,null,2));

/* #region MetamaskManagement */

const  connectToMetamask =  async () => {
  
  if (window.ethereum) {
      const _chainId = await window.ethereum.request({ method: 'eth_chainId' });
      setChainId(_chainId);
     if (_chainId != CHAIN_ID) {
          setStatus(MetaMaskStatus.WRONG_NETWORK);
          alertMetamask();
     } else {
      const walletResponse = await connectWallet();
      setStatus(walletResponse.status);
      setWallet(walletResponse.address);

     }
  }else {
    setStatus(MetaMaskStatus.NO_METAMASK);
    AlertNoMetaMask()

  }
}



function addChainListener() {
  if (window.ethereum) {
    window.ethereum.on("chainChanged", (_chainId) => {
        setChainId(_chainId);
        setStatus(MetaMaskStatus.ACCOUNT_NOT_CONNECTED);
       // setRegistered(false);
        if (_chainId != CHAIN_ID) {
          setStatus(MetaMaskStatus.WRONG_NETWORK);
         // setRegistered(false);
        }       
      });  
  }
}



async function addWalletListener () {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
         setWallet(accounts[0]);
         setNbOfWin(0);
         isRegistered(accounts[0]);//ruse : ne pas utiliser walletAdress car met du temps à se mettre à jour
      } else {
        setWallet("");
        setStatus(MetaMaskStatus.ACCOUNT_NOT_CONNECTED);
        setNbOfWin(0);
        setRegistered(false);
      }
    });
  } 
}



const {contractAddress,abiFileName,CHAIN_ID} =  window['runConfig'];
const contractABI = require('../../abi/' + abiFileName).abi;

/* #endregion */


/* #region Alert Metamask management   */

const [open, setOpen] = useState(false);

const alertMetamask = () => {
  setOpen(true);
 }

const handleClose = async () => {
  setOpen(false);
};

const signIn = async () => {
  setOpen(false);
  if (status==MetaMaskStatus.ACCOUNT_NOT_CONNECTED) {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  }
};

/* #endregion */

/* #region Alert management  */

const [openContractAlert, setOpenContractAlect] = useState(false);
const [contractAlertText, setContractAlectText] = useState(false);

const alertContract = (_message) => {
  console.log(_message);
  setContractAlectText(_message);
  setOpenContractAlect(true);

 }

const handleCloseContractAlert = async () => {
  setOpenContractAlect(false);
};

/* #endregion */


const isRegistered = async(aWallet) =>{
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ action:"isregister",callingaddr:aWallet })
  };
  
  const res = await  fetch(backendURL,requestOptions);
  const data = await res.json();
  if (data.Error==0) {
    setRegistered(true); 
    setNbOfWin(data.nbOfWin);
    setNbOfPlayers(data.nbOfPlayers);
  }else {
    setRegistered(false);
  }
  console.log("isregistered " + JSON.stringify(data) );
};

const register = async () => {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ action:"register",callingaddr:walletAddress })
  };
try {
    const res = await  fetch(backendURL,requestOptions);
    const data = await  res.json();
    setNbOfPlayers(data.nbOfPlayers);
    console.log("register " + JSON.stringify(data));
    if (data.Error==1000)   {
      setRegistered(true);
      
    } else if (data.Error==0){
      setRegistered(true);
      alertContract("Registered");
    }
    
} catch (error) {
      console.log('Error:', error);  
      return {'Error:' : error};
    
};
}//register

const registerButtonClicked = async () => {
      if ((curChainId != CHAIN_ID) && (status != MetaMaskStatus.NO_METAMASK)) {
        setStatus(MetaMaskStatus.WRONG_NETWORK);
        alertMetamask();
      } else {
        if (status === MetaMaskStatus.ACCOUNT_CONNECTED) {
          
          const retVal= register();
          
        } else {
          alertMetamask();
        }
      }
  };



const walletChip=()=>{
  if (status === MetaMaskStatus.ACCOUNT_CONNECTED) {
    return walletAddress;
  } else {
    return "Not Connected";
  }
}


  return (
    <>
      <Box  display="flex"  justifyContent="center" className={colorBg}   id={sectionId}>
      <Grid container  maxWidth="1300px"  className={colorBg}   sx={{paddingTop:5 }} >
          
      <Grid  container   direction="row"   justifyContent="space-around"    
      alignItems="flex-start" paddingLeft={{ xs: "0px", sm: "0px" }} >


          {/* Header  -------------------- */}
          <Grid item  xs={12}  marginLeft={{ xs: "15px", sm: "30px" }} display="flex" alignItems="normal" > 
              <Box  className={lightText ? 'myheading' : 'myheading dark'}  >
                  {headline}
              </Box>
              { (nbOfPlayers>0)  &&
              <Chip variant="outlined" color="primary" size="small"  sx={{ml:"15px", mt:"5px"}}  label={nbOfPlayers.toLocaleString() + " players"}/>
              }
          </Grid>
          
          {/* subHeader text */}    
          <Grid item xs={12} marginLeft={{ xs: "15px", sm: "30px" }}>
              
              <Box  className={lightText ? 'mynormaltext' : 'mynormaltext dark' }>
                  {subheadline} 
              </Box>
             {/*
              { ((status==MetaMaskStatus.ACCOUNT_CONNECTED) && (nbOfPlayers>0))  &&
              <Box  className={lightText ? 'mynormaltext' : 'mynormaltext dark' }>
              <Chip label={nbOfPlayers + " players"}/>
              </Box>
              }
            */}

              <Box  className={'mysmalltext dark' }>
              {apologyLine}
              </Box>
          </Grid>

          {/* Button item */}
          <Grid item xs={12} sx={{mt:'50px'}} >
              
              {/* subHeader text */}    
              <Box   display="flex"  justifyContent="center"
                      className={lightText ? 'mynormaltext' : 'mynormaltext dark'}>
                  <RaffleRegisterButton status={status} onClick={registerButtonClicked} 
                  raffleCompleted={raffleCompleted} registered={registered} >Register</RaffleRegisterButton>
               </Box>

          </Grid>

          {/* chip wallet */}
          <Grid item xs={12} sx={{mt:'20px'}} >
              
              {/* subHeader text */}    
              <Box   display="flex"  justifyContent="center"
                      className={lightText ? 'mynormaltext' : 'mynormaltext dark'}>
                  <Chip label={walletChip()}/>
               </Box>

          </Grid>


          {/* Nb Of Win */}
          <Grid item xs={12} sx={{mt:'20px'}} >
              
 
              <Box   display="flex"  justifyContent="center"   textAlign="center"                   >
                 {(nbOfWin>0)  &&
                 
                  <Typography> 
                        <p> {"You won " + nbOfWin + " mories!"} </p>
                        <p>Look for the Opensea Logo in the lists </p>
                        <p>below and click on it to buy </p>
                   </Typography>
                  }
               </Box>
   

          </Grid>


        
        
        {/* draws list */}    
        <Grid item sx={{mt:'70px',mb:'75px' }}>
           <Grid container display="flex" rowSpacing={1}  maxWidth="800px"
                justifyContent="center" direction="row" columnSpacing={{ xs: 2, md: 3 }} >
              {[1,2,3,4,5,6,7,8,9,10].map((i) =>(
              <Grid item key={i}  >
                  <DrawList   draws={draws} raffleNb={i} raffleTitle={"Raffle "+i} walletAddress={walletAddress} />
              </Grid>
              ))}
           </Grid>        
      
        </Grid>




      </Grid>{/*Container*/}
       
      </Grid>
      </Box> 
      <AlertNoMetaMask open={open} handleClose={handleClose} status={status} signIn={signIn}  />
      <AlertContract open={openContractAlert} handleClose={handleCloseContractAlert} message={contractAlertText} />
    </>
  );
}

export default RaffleSection;