import { useEffect, useState,useRef } from "react";
import './SpaceDriveSection.css';
import {Box,Chip,Grid,Typography,Collapse,Slide,Grow,Fade,Zoom,Button} from '@mui/material';




const structureImg = "images/spacedrivesection/SpaceNuggets.png";
const voletImg = "images/spacedrivesection/Volet.png";


const smallPoleImg = "images/allsection/PoteauPetitSite.png";
const bigPoleImg = "images/allsection/PoteauGrandSite.png";





function SpaceDriveSection({
  sectionId,
  fishes,
}) {

  const [checked, setChecked] = useState(false);
  const [isFullyOpen, setisFullyOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);





  const renderImgTop = () => {
    if (true) {
      return <img className='spacedrive-top-img'  src={fishes[5]}  />;
    }
  }
  
  const renderImgBot = () => {
    if (true) {
      return <img className='spacedrive-bot-img'  src={fishes[6]}  />;
    }
  }
  


  const onOpenCloseClick = () => {
   
   setIsOpen((prev) => !prev);
  
       
   
    };

    
  const onTransitionDone = () => {
   
          setisFullyOpen(isOpen);

    };

    const handleChange = () => {
      
    };
  



  return (
    <>
    <Box  display="flex"  justifyContent="center"   id={sectionId} >
        <Grid container   className={'topcontainer'}  justifyContent="center"  >
            <Grid item>
                <Box  className={'relativecontainer noselect'} >
                            {/*  Poisson relataf  */ }
                            {(false) &&
                            <Box>
                            {renderImgTop()}
                            {renderImgBot()}                  
                            </Box>
                            }
                            {/* Image cadre */}
                            <Box >
                            <img  src={structureImg}></img>
                            </Box>



                       { (true) && 
                        <Box className={'spacedrive-voletbox'}  onClick={a=>onOpenCloseClick()}  >
                        
                          <Collapse orientation="vertical" in={!isOpen}  
                               {...(true ? { timeout: 3000 } : {})}
                               onExited={a=>onTransitionDone()}
                               onEntered={a=>onTransitionDone()}>
                               <Box  className={'noselect'}>
                               <img  src={voletImg}  ></img>
                               </Box>
                          </Collapse>                               
                         </Box> 
                        }

                        <Box  className={'spacedrive-openclosebox'} >
                            <Grid container direction="column" justifyContent="center" 
                                  alignItems="center"  paddingTop='25%'  >
                                  
                                  <Grid item>{(isFullyOpen)?'Open':'Closed'} </Grid>
                                
                            </Grid>
                        </Box>  
                        <Box>
                              <button  className={'spacedrive-openclosebtn'} onClick={a=>onOpenCloseClick()} ></button>
                        </Box>  
                           
                            
                </Box>

            </Grid>


        </Grid>
    </Box> 
    </>
  );
}

export default SpaceDriveSection;