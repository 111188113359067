import { useEffect, useState,useRef } from "react";
import './RoadMapSection.css';
import {Box,Chip,Grid,Typography,Collapse,Slide,Grow,Fade,Zoom,Button} from '@mui/material';
import AlertContract from "../../components/MUI/AlertContract";




const structure1Img = "images/roadmapsection/RoadMap_Structure1.png";
const structure2Img = "images/roadmapsection/RoadMap_Structure2.png";

const sellOutImg = "images/roadmapsection/RoadMap_SellOut.png";

const drawingImg = "images/roadmapsection/RoadMap_Drawing.png";
const senatorImg = "images/roadmapsection/RoadMap_Senators.png";
const merchImg = "images/roadmapsection/RoadMap_Merch.png";
const oneoneImg = "images/roadmapsection/RoadMap_OneOne.png";
const qmImg = "images/roadmapsection/RoadMap_QM.png";

const smallPoleImg = "images/allsection/PoteauPetitSite.png";
const bigPoleImg = "images/allsection/PoteauGrandSite.png";



function RoadMapSection({
  colorBg,
  lightText,
  sectionId,
  isSellOutDone,
  isDrawingDone,
  isSenetorsDone ,
  isMerchDone,
  isOneOneDone,
  isUnknownDone,
  alerts
}) {

  const [checked, setChecked] = useState(false);

  const renderImgTop = () => {
    if (true) {
      return <img className='roadmap-top-img'  src='images/F3_Flou.png'  />;
    }
  }
  
  const renderImgBot = () => {
    if (true) {
      return <img className='roadmap-bot-img'  src='images/F4_Focus.png'  />;
    }
  }
  
/* #region Alert management  */

const [openContractAlert, setOpenContractAlert] = useState(false);
const [contractAlertTitle, setContractAlertTitle] = useState(false);
const [contractAlertText, setContractAlertText] = useState(false);

const alertContract = (alertNb) => {
  setContractAlertTitle(alerts[alertNb].title);
  setContractAlertText(alerts[alertNb].corpse);
  setOpenContractAlert(true);

 }

const handleCloseContractAlert = async () => {
  setOpenContractAlert(false);
};





/* #endregion */








  return (
    <>
    <Box  display="flex"  justifyContent="center"   id={sectionId} >
        <Grid container  className={'topcontainer'}  justifyContent="center" >
            <Grid item>
                <Box  className={'relativecontainer'} >
                           
                            {/* Image cadre */}
                            <Box >
                            <img  src={structure1Img}></img>
                            </Box>

                          {/*  Panneau selloff */ }  
                          <Box className={'roadmap-sellout'} >
                                  <img  src={sellOutImg}></img>  
                            </Box>
                            <Box >
                              <button className={'roadmap-selloutbtn'} onClick={a=>alertContract(11)}  ></button>
                            </Box> 

                           {/*  Panneau drawings */ }  
                           <Box className={'roadmap-drawings'} >
                                  <img  src={drawingImg}></img>  
                            </Box>                           
                            <Box >
                              <button className={'roadmap-drawingsbtn'} onClick={a=>alertContract(2)} ></button>
                            </Box>  
                            {/*  Panneau senators */ }  
                            <Box className={'roadmap-senators'} >
                                  <img  src={senatorImg}></img>  
                            </Box> 
                            <Box >
                              <button className={'roadmap-senatorsbtn'} onClick={a=>alertContract(3)} ></button>
                            </Box>     

                            <Box >
                              <button className={(isSellOutDone)? 'roadmap-selloutlight roadmap-green': 'roadmap-selloutlight roadmap-red'} ></button>
                            </Box> 

                            <Box >
                              <button className={'roadmap-drawinglight roadmap-red'} ></button>
                            </Box> 

                            <Box >
                              <button className={'roadmap-senatortlight roadmap-red'} ></button>
                            </Box> 






                 </Box>
            </Grid>
            
            
            {/* A parir de merch  */}
            <Grid item>
                <Box  className={'roadmap-container'} >
                           
                            {/* Image cadre */}
                            <Box >
                            <img  src={structure2Img}></img>
                            </Box>
                           
                            {/*  Panneau merch */ }  
                            <Box className={'roadmap-merch'} >
                                  <img  src={merchImg}></img>  
                            </Box>    
                            <Box >
                              <button className={'roadmap-merchbtn'} onClick={a=>alertContract(4)} ></button>
                            </Box>     


                            {/*  Panneau oneone */ }  
                            <Box className={'roadmap-oneone'} >
                                  <img  src={oneoneImg}></img>  
                            </Box>   
                            <Box >
                              <button className={'roadmap-oneonebtn'} onClick={a=>alertContract(5)} ></button>
                            </Box>                                 
                            {/*  Panneau question mark */ }  
                            <Box className={'roadmap-qm'} >
                                  <img  src={qmImg}></img>  
                            </Box> 
                            <Box >
                              <button className={'roadmap-qmbtn'} onClick={a=>alertContract(6)} ></button>
                            </Box>     

                            <Box >
                              <button className={(isMerchDone)? 'roadmap-merchlight roadmap-green': 'roadmap-merchlight roadmap-red'} ></button>
                            </Box> 
                            <Box >
                              <button className={(isOneOneDone)? 'roadmap-oneonelight roadmap-green': 'roadmap-oneonelight roadmap-red'} ></button>
                            </Box> 
                            <Box >
                              <button className={(isUnknownDone)? 'roadmap-qmlight roadmap-green': 'roadmap-qmlight roadmap-red'} ></button>
                            </Box> 
                           



                </Box>

            </Grid>

        </Grid>
    </Box> 
    <AlertContract open={openContractAlert} handleClose={handleCloseContractAlert} title={contractAlertTitle} corpse={contractAlertText} />
    </>
  );
}

export default RoadMapSection;