import { useEffect, useState } from "react";
import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { green , yellow} from '@mui/material/colors';
import ContractItem from "./ContractItem";
import WhiteListManager from "./WhiteListManager";
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';




import MetamaskStatus ,{
   getCurrentWalletConnected,
   ReadBC,
   WriteBC
 } from "../../utils/interact.js";
import MoneyManager from "./MoneyManager";


 
 const yellowPaper = yellow[500]; 
 

function ContractManager({
  colorBg,
  lightText,
  sectionId,
  headline,
  imgbottom,
  maxTokenToMint,
  token2MintDefault,
  tokenPrice
}) {

//State variables
const [walletAddress, setWallet] = useState("");
const [status, setStatus] = useState("");
const[values,setValues] = useState( []);

const items =[
{
  varName : 'NAME',
  id:0,
  itemType:1,
  readFn : 'name',
  writeFn :''
},
{
  varName : 'SALE ACTIVE',
  id:1,
  itemType:2,
  readFn : 'saleIsActive',
  writeFn :'flipSaleState'
},
{
  varName : 'CLAIM ACTIVE',
  id:2,
  itemType:2,
  readFn : 'claimIsActive',
  writeFn :'flipClaimState'
},
{
  varName : 'PROVENANCE',
  id:3,
  itemType:3,
  readFn : 'PROVENANCE',
  writeFn :'setProvenanceHash'
},
{
  varName : 'BASE URI',
  id:4,
  itemType:3,
  readFn : 'baseURI',
  writeFn :'setBaseURI'
},
{
  varName : 'REVEAL_TIMESTAMP',
  id:5,
  itemType:3,
  readFn : 'REVEAL_TIMESTAMP',
  writeFn :'setRevealTimestamp'
},
{
  varName : 'startingIndexBlock',
  id:6,
  itemType:2,
  readFn : 'startingIndexBlock',
  writeFn :''
},
{
  varName : 'STARTING INDEX',
  id:7,
  itemType:2,
  readFn : 'startingIndex',
  writeFn :'setStartingIndex'
},
{
  varName : 'OWNER',
  id:8,
  itemType:1,
  readFn : 'owner',
  writeFn :''
},
];

const {contractAddress,abiFileName} =  window['runConfig'];
const contractABI = require('../../abi/' + abiFileName).abi;



useEffect(async () => {
  console.log("Useeffect de contract manager");
  const {address, status} = await getCurrentWalletConnected();
  setWallet(address)
  setStatus(status);
  addWalletListener(); 

  items.map((item) =>{ 
   setValues([...values,''] );
  });

items.map(async (item) =>{ 
  if (item.readFn != '') ReadFromBC(item.readFn,item.id,[]);
});




}, []);;



function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Account changed.");
  } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top right button.");
      }
    });
  } else {
    setStatus("Install metamask");
  }
}

  const ReadFromBC = async (fn,id,args) => {
   if (fn!='') {
    var retVal = await ReadBC(contractABI,contractAddress,fn,args);
   // alert("Rmanager-Read value before " + values.toString());
    values[id]=retVal;
    setValues([...values, { id: id, name: retVal }]);
   // alert("Rmanager-Read value after " + values.toString());
  //alert("Read from manager retval : " + retVal + " id = "+ id + "  values[id]=" + values[id]);
    return retVal;  
   }
 }

 const WriteToBC = async (fn,id,args) => {
  if (walletAddress.length > 0) {
    const { success,status } = await WriteBC(contractABI,contractAddress,fn,0,args);
    if (! success) {alert(status);} 
  } else {
     const walletResponse = await getCurrentWalletConnected();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  }
}
 



  return (
    <>
      <Box  display="flex"  justifyContent="center" sx={{ bgcolor: colorBg}}  id={sectionId}>
      <Grid container  maxWidth="1300px"     sx={{paddingTop:5, bgcolor: colorBg   }} >
  
    {/* Header  -------------------- */}
          <Grid item xs={12} paddingLeft="30px" paddingBottom="30px"> 
              <h1  className={lightText ? 'myheading' : 'myheading dark'}>
                  {headline}
              </h1>
              <h3>{status+ " " + walletAddress }</h3>
          </Grid>
          
    {/* Main Block  -------------------- */}
          <Grid container justifyContent="center" direction="column"  alignItems="center" paddingBottom="50px">
          <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         <Typography variant="h5" component="h5" paddingBottom="10px" >REGULAR ITEMS</Typography>  
        </AccordionSummary>
    
        <AccordionDetails>
          <Paper    sx={{ p: '2px 4px', 
                    alignItems: 'center', direction: 'column',width:'800px',padding:'15px', backgroundColor:yellowPaper }}>
            
           
                { items.map( (item,i) => {
                return  <ContractItem  key={i}   item={item}  varValue={values[i]} 
                            readFromBC={ReadFromBC} writeOnBC={WriteToBC}  />
              } )}            
          </Paper>
          </AccordionDetails>
      </Accordion>
          </Grid>

    {/* Main Block  -------------------- */}

         
         <MoneyManager  readFromBC={ReadFromBC} writeOnBC={WriteToBC} 
                  status={status} 
                  walletAddress={walletAddress} 
                  owner={values[8]}   />
  
 {/* <WhiteListManager  readFromBC={ReadFromBC} writeOnBC={WriteToBC}  />  */}

      </Grid>
      </Box> 
     

    </>
  );
}

export default ContractManager;