import * as React from 'react';
import Button from '@mui/material/Button';
import MetamaskStatus from '../../utils/interact.js';
import { styled } from '@mui/material/styles';
import { green,yellow,orange } from '@mui/material/colors';

const greenBtn = orange['300']; 


const MintButton = styled(Button)(({ theme }) => ({
   variant:'contained',
   elevation : 10, 

   [theme.breakpoints.between("xs", "mob1")]: {
      width:'150px',
      height:'50px',
      fontSize:15,
      borderRadius:'10px',
    },
    [theme.breakpoints.up("mob1")]: {
      width:'180px',
      height:'50px',
      fontSize:16,
      borderRadius:'20px',
    },
    [theme.breakpoints.up("mob2")]: {
      width:'200px',
      height:'60px',
      fontSize:18,
      borderRadius:'20px',
    },
    [theme.breakpoints.up("mob3")]: {
      width:'250px',
      height:'75px',
      fontSize:22,
      borderRadius:'20px',
    },
   color:'white',
   backgroundColor: greenBtn,
   '&:hover': {
     backgroundColor: 'tomato',
   },
 }));



const btnLabel = (props) => {


   if (props.phaseStatus=='aftersale')  return "Buy On Opensea";
   
   if (props.phaseStatus=='beforesale') return "Not Yet";

   switch (props.status) {
         case MetamaskStatus.ACCOUNT_CONNECTED :
         if (props.phaseStatus=='presale') {
           return "Claim " + props.token2Mint + " SSF";
            } else {
            return "Mint " + props.token2Mint + " SSF";
         }
         case MetamaskStatus.ACCOUNT_NOT_CONNECTED :
         return "Connect to Metamask";

         case MetamaskStatus.WRONG_NETWORK :
         return "Wrong Network";

         case MetamaskStatus.NO_METAMASK:
         return "Install Metamask";
   }


}







export default function SaleButton(props) {
  return (
   
   <>
   
   
      <MintButton  sx={{ boxShadow: 6 }}  onClick={props.onClick}  disabled={props.phaseStatus =='beforesale'} > 
   
                  {btnLabel(props)}
           </MintButton>
   
  </>
   );
}

