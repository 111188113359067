import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import { FaBars,FaTimes } from 'react-icons/fa'
import './Navbar.css'
import { IconContext } from 'react-icons/lib'
import {  NavHashLink } from 'react-router-hash-link';


export default function NavBar({
    colorBg,
  }) {
    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)
    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
        setButton(false);
        } else {
        setButton(true);
        }
    };

    useEffect(() => {
        showButton();
      }, []);



  window.addEventListener('resize', showButton);
  


    return (
        <>
        <IconContext.Provider value={{ color: '#fff' }}>
           <div className="navbar " id='top'>
                <div className="navbar-container container">
                    <Link to='/'  className="navbar-logo" onClick={closeMobileMenu}>
                        <img src='images/deco/logo.png' className='navbar-icon' />
                        Spiky Space Fish
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        {click ? <FaTimes/> : <FaBars/>}
                    </div>
                    <ul className={click ? 'nav-menu active': 'nav-menu'}>

{/*
                    <li className="nav-item">
                            <NavHashLink smooth to='/home#Learn' className='nav-links' activeStyle={{color: "yellow"}} onClick={closeMobileMenu}>
                                Learn
                            </NavHashLink>
                    </li>
*/}
  
                    
                    <li className="nav-item">
                            <NavHashLink smooth to={'/home#mint'} className='nav-links' activeStyle={{color: "yellow"}} onClick={closeMobileMenu}>
                                NFT
                            </NavHashLink>
                    </li>
                        <li className="nav-item">
                            <NavHashLink smooth to={'/home#faq'}  className='nav-links' activeStyle={{color: "yellow"}} onClick={closeMobileMenu}>
                                FAQ
                            </NavHashLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavHashLink smooth to={'/home#team'}  className='nav-links' activeStyle={{color: "yellow"}} onClick={closeMobileMenu}>
                                Team
                            </NavHashLink>
                        </li>


                        <li className="nav-item">
                            <NavHashLink smooth to={'/home#roadmap'}  className='nav-links' activeStyle={{color: "yellow"}} onClick={closeMobileMenu}>
                                Roadmap
                            </NavHashLink>
                        </li>
{/*
                           <li className="nav-item">
                            <NavHashLink to='/provenance' className='nav-links' activeStyle={{color: "yellow"}} onClick={closeMobileMenu}>
                                Provenance
                            </NavHashLink>
                        </li>
  */} 
                    </ul>

                </div>
           </div>
           </IconContext.Provider>
        </>
    )
}
