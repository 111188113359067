import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
      fontFamily: 'Roof runner',
     },
  breakpoints: {
      values: {
        xs: 0,
        mob1 : 350,
        mob2 : 420,
        mob3 : 500,
        sm: 650,
        md: 900,
      },
    },
   })

export default theme;