import { useEffect, useState,useRef } from "react";
import './FAQSection.css';
import {Box,Paper,Grid,Typography,Collapse,Slide,Grow,Fade,Zoom,Button} from '@mui/material';
import FAQLoop from "./FAQLoop.js";
import {mod} from '../../utils/myLib';


const structureImg = "images/faqsection/FAQ_Structure.png";

function FAQSection({
  colorBg,
  lightText,
  sectionId,
  headline,
  imgbottom,
  qas
}) {

  const [checked, setChecked] = useState(false);
  const [transitionDone, setTransitionDone] = useState(false);

/* #region team loop */

const transitionDuration = 300;

const [activeIndex, setActiveIndex] = useState(0);
const [elementIn, setElementIn] = useState(true);

const onClick = (way) => {
  setElementIn(false);
    setTimeout(() => {
        setElementIn(true);
        setActiveIndex(mod(activeIndex + way, qas.length));
     }, transitionDuration);
  };

/* #endregion */




  const renderImgTop = () => {
    if (true) {
      return <img className='faq-top-img'  src='images/F3_Flou.png'  />;
    }
  }
  
  const renderImgBot = () => {
    if (true) {
      return <img className='faq-bot-img'  src='images/F4_Focus.png'  />;
    }
  }


  return (
    <>
    <Box  display="flex"  justifyContent="center"   id={sectionId}>
        <Grid container className={'topcontainer'}     justifyContent="center"  >
            <Grid item>
                <Box  className={'relativecontainer'} >
                            {/*  Poisson relataf */ }
                           {(false) && <Box>
                            {renderImgTop()}
                            {renderImgBot()}                  
                            </Box>
                            }
                            {/* Image cadre */}
                            <Box className={'noselect'} >
                            <img  src={structureImg}></img>
                            </Box>
            
                            {/*  Panneau relative */ }
                            <Box className={'faq-paperbox'}  
                                    marginTop={{ xs:'25px', sm: '35px' }} 
                                    paddingRight={{ xs:'25px', sm: '35px' }}
                                    paddingLeft={{ xs:'20px', sm: '30px' }} 
                                    
                                    >
                              <Paper className={'faq-paper'} sx={{backgroundColor:'black'}} >                                      
                                  <Collapse
                                      in={elementIn}
                                      timeout={transitionDuration}
                                      orientation="vertical"
                                      >
                                      <Box className={'faq-question noselect'}  >{qas[activeIndex].question}</Box>
                                      <Box className={'faq-answer noselect'}>{qas[activeIndex].answer}</Box>
                                  </Collapse>
                                  </Paper>
                            </Box> 
                            {/*  
                            <Box >
                              <button className={'faq-membersbuttonbox'} onClick={a=>onClick(1)} ></button>
                            </Box>    
                            */}
                            <Box >
                              <button className={'faq-membersbuttonRight'} onClick={a=>onClick(1)} ></button>
                            </Box>  
                            <Box >
                              <button className={'faq-membersbuttonLeft'} onClick={a=>onClick(-1)} ></button>
                            </Box> 
                          
                            <Box >
                              <button className={'faq-faqbtn'} onClick={a=>onClick(1)} ></button>
                            </Box>
                </Box>

            </Grid>


        </Grid>
    </Box> 
    </>
  );
}

export default FAQSection;