import { green , yellow} from '@mui/material/colors';
  

const downLoadList =[
  {
    id:0,
    fileName : 'CryptoMories-Structure.json',
    icon:'images/deco/ipfs-logo.svg' ,
    link : 'https://ipfs.io/ipfs/QmbhjShaNGdyByAYTnd1dzVYYNxwhZNJiUMnfWZgp3s2q7/CryptoMories-Structure.json',
  },{
  id:1,
  fileName : 'CryptoMories-GenProfilsOfficial.json',
  icon:'images/deco/ipfs-logo.svg' ,
  link : 'https://ipfs.io/ipfs/QmbhjShaNGdyByAYTnd1dzVYYNxwhZNJiUMnfWZgp3s2q7/CryptoMories-GenProfilsOfficial.json',
  },{
    id:2,
    fileName : 'Presale Provenance Transaction',
    icon:'images/deco/etherscan.png' ,
    link : 'https://etherscan.io/tx/0xc5c683f48d6f7fa73864f523ab6acdac12da6c33ddfdf41bc269e0490a8f7be3',
},{
  id:3,
  fileName : 'lastMint Transaction',
  icon:'images/deco/etherscan.png' ,
  link : 'https://etherscan.io/tx/0x354ccac45d43d070bc23f493b84033f6fbd0a26f5e4f66787ed26e05f2c50073',
},{
  id:4,
  fileName : 'setStartingIndex Transaction',
  icon:'images/deco/etherscan.png' ,
  link : 'https://etherscan.io/tx/0x03c4965447dcccca1d7da48b14e10f0366c788de5e3767e6bb98eaa2a115bbb0',
},{
  id:5,
  fileName : 'Provenance Record File',
  icon:'images/deco/ipfs-logo.svg' ,
  link : 'https://ipfs.io/ipfs/QmdUFVVaW2tdmVLYNfpX9TnG6jQPLShrVzyATGf1BsyUGQ',
},{
  id:6,
  fileName : 'Post-Reveal Proveance Transaction',
  icon:'images/deco/etherscan.png' ,
  link : 'https://etherscan.io/tx/0x08b4ab1a8f86ee8656fb82268dd03b19ef0b516fd075fa42255748b351a040b7',
}];
 


const   {contractAdress} =  window['runConfig'];

export const provenance = {
    
    
  colorBg: 'blackBg',
  lightText: true,
  lightTextDesc: true,
  headline: 'Fairness and provenance',
  subheadline: <p>This page presents the fair distribution 
        process and the provenance record of each 'Spiky Space Fish' that will ever exists.
        <br/>
        </p>,
  headerFairness: 'Fairness',
  fairNessText1 : <p>We had to garantee that the distribution of the generated 
    profils (characters) was fair,
    meaning that, at the purchase time, nobody could tell what profil 
    he will get, which included us, the team. <br/><br/>
    In order to do so, we first generated randomly 
10 0000 profils, numbered from 1 to 10 000, from the attributs and traits 
that we had created.
The full list of attributs with their traits is 
what we called the structure. We stored it in a file named
CryptoMories-Structure.json.<br/>
We also stored the generated profils in a file named CryptoMories-GenProfilsOfficial.json.
This file provides not only the profil description, 
but also the initial order of the profils.<br/><br/>

We then froze the structure and the ordered profils 
by storing the two files in a folder in the IPFS (Interplanetay File System).
And finally, before the presale, we stored the IPFS address 
of the folder in the smart contract, in the IWWON_PROVENANCE variable. <br/>
</p>,
fairNessText2:<p><br/>From there, we had to distribute randomly the profils to the tokens.<br/>
 After the very last mint, 
  the smart contract registered the value of the current block number in the variable startingIndexBlock. 
  This is the random part of the process as we had no control or 
  idea on what would be the block number at the last mint, and when would be the last mint. <br/>
  From this block number, we derived a number between 0 and 9,999 (see  methods in the smart contract code). 
  This derived number is what we called the starting index. 
  <br/>The "setStartingIndex" transaction, done after the last mint returned the number 620.<br/>

  It simply means that the token 0 would get the profil  621, the token 1 the profil 622 ...<br/>

  More generally, the profil number assigned to a CryptoMories token is given by the following formula :<br/><br/>
  <center>assigned profil Id = (620 + 1 + TokenId) MOD 10,000 </center>
<br/><br/>Please, notice the the tokens are numbered from 0 to 9,999 whearas the profils from 1 to 10,000, 
which explains the "+ 1" in the formula.
      
  
  </p>
  ,
  provenanceText : <p>The proveance record garantees the provenance of the image of the NFTs, and this for eternity. 
    Say differently, the owner of a token has to be sure that the image he gets is the original, 
    in a decentrelized way, ie independantly of any servers or institution.<br/>
    <br/>In order to do so, each Cryptomories image is encrypted using the SHA-256 algorithm. 
    The output of the encryption is called an hash, ie a long string of characters.
    A combined string is obtained by concatenating (adding one afer the other)  
    the hash of each CryptoMories image following the token order.<br/> 
    The final proof is obtained by hashing this combined string. 
    This is the final provenance record stored on the smart contract.<br/>




  </p>,
  linkList:downLoadList,
  contractEthescan : 'https://etherscan.io/address/' + contractAdress,
  dividerColor:green[600],
  dividerAlpha:0.5,
  headerProvenance: 'Provenance',
  sectionId:'mint',
  imgtransition:'',
  maxTokenToMint: 20,
  token2MintDefault:1,
  tokenPrice:0.02
  };




  export const provenance_cm = {
    
    
    colorBg: 'blackBg',
    lightText: true,
    lightTextDesc: true,
    headline: 'Fairness and provenance',
    subheadline: <p>This page presents the fair distribution 
          process and the provenance record of each cryptomories that will ever exists.
          <br/>
          </p>,
    headerFairness: 'Fairness',
    fairNessText1 : <p>We had to garantee that the distribution of the generated 
      profils (characters) was fair,
      meaning that, at the purchase time, nobody could tell what profil 
      he will get, which included us, the team. <br/><br/>
      In order to do so, we first generated randomly 
10 0000 profils, numbered from 1 to 10 000, from the attributs and traits 
that we had created.
The full list of attributs with their traits is 
what we called the structure. We stored it in a file named
CryptoMories-Structure.json.<br/>
We also stored the generated profils in a file named CryptoMories-GenProfilsOfficial.json.
 This file provides not only the profil description, 
 but also the initial order of the profils.<br/><br/>

We then froze the structure and the ordered profils 
by storing the two files in a folder in the IPFS (Interplanetay File System).
And finally, before the presale, we stored the IPFS address 
of the folder in the smart contract, in the IWWON_PROVENANCE variable. <br/>
  </p>,
  fairNessText2:<p><br/>From there, we had to distribute randomly the profils to the tokens.<br/>
   After the very last mint, 
    the smart contract registered the value of the current block number in the variable startingIndexBlock. 
    This is the random part of the process as we had no control or 
    idea on what would be the block number at the last mint, and when would be the last mint. <br/>
    From this block number, we derived a number between 0 and 9,999 (see  methods in the smart contract code). 
    This derived number is what we called the starting index. 
    <br/>The "setStartingIndex" transaction, done after the last mint returned the number 620.<br/>

    It simply means that the token 0 would get the profil  621, the token 1 the profil 622 ...<br/>

    More generally, the profil number assigned to a CryptoMories token is given by the following formula :<br/><br/>
    <center>assigned profil Id = (620 + 1 + TokenId) MOD 10,000 </center>
<br/><br/>Please, notice the the tokens are numbered from 0 to 9,999 whearas the profils from 1 to 10,000, 
which explains the "+ 1" in the formula.
        
    
    </p>
    ,
    provenanceText : <p>The proveance record garantees the provenance of the image of the NFTs, and this for eternity. 
      Say differently, the owner of a token has to be sure that the image he gets is the original, 
      in a decentrelized way, ie independantly of any servers or institution.<br/>
      <br/>In order to do so, each Cryptomories image is encrypted using the SHA-256 algorithm. 
      The output of the encryption is called an hash, ie a long string of characters.
      A combined string is obtained by concatenating (adding one afer the other)  
      the hash of each CryptoMories image following the token order.<br/> 
      The final proof is obtained by hashing this combined string. 
      This is the final provenance record stored on the smart contract.<br/>




    </p>,
    linkList:downLoadList,
    contractEthescan : 'https://etherscan.io/address/' + contractAdress,
    dividerColor:green[600],
    dividerAlpha:0.5,
    headerProvenance: 'Provenance',
    sectionId:'mint',
    imgtransition:'',
    maxTokenToMint: 20,
    token2MintDefault:1,
    tokenPrice:0.02
    };

    