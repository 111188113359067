import { green } from '@mui/material/colors';


const fishes = [
  'images/fishes/F1_Focus.png',//0
  'images/fishes/F1_Flou.png',//1
  'images/fishes/F2_Focus.png',
  'images/fishes/F2_Flou.png',//3
  'images/fishes/F3_Focus.png',
  'images/fishes/F3_Flou.png',//5
  'images/fishes/F4_Focus.png',
  'images/fishes/F4_FlouFront.png',//7
  'images/fishes/F4_FlouBack.png',
  'images/fishes/F5_FocusBack.png',//9
  'images/fishes/F_400.png',//10
];






//<p>300 tickets for Cryptomories owners. <br />Register to the <a href="https://www.spikyspacefish.com/learn" target="_blank">raffle</a></p>

const alerts = [
  {id:0,
  title: <p>Claim process </p>,
  corpse :<div>
  <p>To claim for free SpikySpaceFish United (SSFU) , you need to own some SpikySpiceFish (SSF).</p><br/>
<p>Please, connect with the Metamask account that owns the SSF.</p><br/>
<p>You would be able to claim SSFU up to 4 times the number of SSF you own.</p><p> The claim number should appear below the "Claim" button. </p><p>If not, refresh using the refresh button beside.</p>
<p>&nbsp;</p>
  </div>},
    {id:1,
    title: <p>Mint policy </p>,
    corpse :<div>
                  <p>The SSF are sold at 0.02 ETH, plus gas fee.</p>
              <p>&nbsp;</p>
              <p>Claim phase :&nbsp; You can claim for free 3 times the token you own plus 1 token to cover the gas Fee</p>
              <p>&nbsp;</p>
              <p>Sale : 10 tokens max per request</p>
            </div>
    },
    { id:2,
      title: <p>Originals raffle</p>,
      corpse :<div>
                    <p>200 original stamped hand-drawings of the SSF attributs and traits will be raffled.<br /><br /></p>
                    <p>The winners will receive by mail the physical drawings and get an NFT to authentificate it.</p>
              </div>
    },
    { id:3,
      title: <p>Senators election</p>,
      corpse :<p>3x random community members will be picked to be given voting power over the team decisions.<br/><br />
                  This is a monthly mandate.</p>
    },
    { id:4,
      title: <p>SSF Merch</p>,
      corpse :<div>
                  <p>Super hoodies, hats, tee-shirts, all the cool stuff!<br/></p>
                  <p>Necklaces will produced on demand, only for fish holders. The necklace is a key<br />wearable on Lejopan, particularly amongst the Followers of the Might Fish cult.</p>
               </div>
    },
    { id:5,
      title: <p>One / One</p>,
      corpse :<div>
                 <p>A series of animated  unique  posters portrays the life on Lejopan and the different landscapes<br />found on the planet.</p>
<p>&nbsp;</p>
                <p>It also portrays the different species cohabiting the eco-system.</p>
                <p><br />Lejopan is home to sapiens, aliens, reptilians, apes, hairy beasts, mega-rats, super-cats<br />and civilised-ducks alike various other droids and outer-space curiosities.</p>
                </div>
    },
    { id:6,
      title: <p>Leojopan</p>,
      corpse :<div>
<p>There are 3 dominant mindsets amongst Lejopaners :</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;Follower of the Mighty Fish cult&rsquo;</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;Orbit Patroller&rsquo;</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;- and &lsquo;Space Nugget Trafficant&rsquo;.</p><br/>
<p>Each SSF_NFT holder will be able to claim a free Lejopaner.</p>
<p>&nbsp;</p>
<p>And then, Leojopan becomes alive...</p>
                  
                </div>
    },
    { id:7,
      title: <p>Hand drawings </p>,
      corpse :<div>
                    <p>Ink hand drawing is at the very heart of the collection.&nbsp;<br /><br />
                    The Website mirrors this philosophy to create an original experience.<br /><br />
                    Do not hesitate to click on every frame.</p>
                  </div>
    },
    { id:8,
      title: <p>SSF random-generation</p>,
      corpse :<div>
                <p>Every attributes are based on ink hand drawings, 
                  then scanned and colorized.</p>
                <p><br />The random-generation extracts profils 
                      from attributes such as hat, tail, mouth, fin ...<br /><br /></p>
                
                  
                </div>
    },
    { id:9,
      title: <p>Traditional indian ink</p>,
      corpse :<div>
<p>Indian ink is welll known for the depth and quality of its black pigment.</p>
<p>It is widely used in comics as it provides accuracy and texture.&nbsp;<br /><br />With more than 15 years 
of experience, Paul has become a master of this traditional technic.</p>
                  
                </div>
    },
    { id:10,
      title: <p>More than 200 attributes</p>,
      corpse :<div>
              <p>Yes, Paul is a madman</p>
              </div>
    },
    { id:11,
      title: <p>Roadmap</p>,
      corpse :<div>
              <p>Roadmap starts after the collection is sold out.</p><br/>
              <p>Watch the red lights!</p>
              </div>
    }
    ,
    { id:12,
      title: <p>Mint</p>,
      corpse :<div>
              <p>Mint request sent</p><br/>
              <p>Press the refresh button below once Metamask confirms</p>
              
              </div>
    }    ,
    { id:13,
      title: <p>Claim</p>,
      corpse :<div>
              <p>No SSFU to claim. </p><br/>
                <p>Either you don't own SSF or you claimed them already</p>
              </div>
    }
]




export const faq = {
    colorBg: 'blackBg',
    lightText: true,
    lightTextDesc: true,
    headline: 'FAQ',
    sectionId:'faq',
    imgbottom : 'images/F3_Focus.png',
    //imgbottom:'https://drive.google.com/uc?id=16vY65rYolJQLr-mO4sneGsG81iWQoxCj',
    qas : [
    {
      id:1,
      question : <p>What is a Spiky Space Fish (SSF)?"</p>,
      answer :  <p>Spiky Space Fish is your entry ticket to the magical word of Lejopan. 
                  It is a collection of 10,000 randomly generated fish. 
                  Attributes and traits have been traditionally hand-drawn with Indian ink, then scanned to be colorized digitally. </p>
                  },
{
      id:2,
      question : <p>How can I get a SSF?</p> ,
      answer :  <p> You need a Metamask wallet with some ETH on it.<br/> You will be able to mint (buy) 
                    them on this website and
                    will cost you 0.06 ETH plus gas per SSF. </p>
    },
    {
      id:3,
      question : <p>Do I need to get an aquarium for my fish?</p>,
      
      answer :  <p> SSF can be kept in space-arium.<br/> However, it is simpler to keep them in your wallet. </p>
    },
    {
    id:4,
    question : <p>What can I enter the presale?</p>,
    answer :  <p> There are 600 tickects for the whitelist: <br/>
                  300 tickets are raffled for the cryptomories owners <br/>
                  300 will be given on the SSF discord.
              </p>
  },
  {
    id:5,
    question : <p>When is the drop ?</p>,
    answer :  <p> Drop date will be announced soon.
              </p>
  },
  {
    id:6,
    question : <p>With all the scams online, how can I trust SSF NFT to be a legitimate project?</p>,
    answer :  <p> The art is too much effort for it to be a scam. 😊
                Seriously, we count among our team members iwwon and Bignenet, who have successfully launched the cryptomories and los samos. 
                <br/>Hope on their discord to find out how well these projects are doing !
              </p>
  },
      {
        id:7,
        question : <p>Do you provide High Res?</p>,
        
        answer :  <p>"size print. 
                  High res files will available for SSF owner on the website. High enough to be printed up to 200cm x 200 cm.<br/>
                  </p>
      },
      {
        id:8,
        question : <p>Why do you do NFT's?</p>,
        
        answer :  <p>Digital art is not a joke, it is a testimony of our time, and we are honoured to be cavemen of the 2020's, digitally painting the walls of the Crypto-cave.<br/>
                  </p>
      },
        
    ]
  };
  
export const roadmap = {
    headline: 'ROADMAP',
    sectionId:'roadmap',
    isSellOutDone : true,
    isDrawingDone : false,
    isSenetorsDone : false,
    isMerchDone : false,
    isOneOneDone:false,
    isUnknownDone:false,
    alerts :alerts
  };



  export const beforesale = {
    headline: 'CLAIM PHASE TODAY AT 4 PM EST',
    subheadline: <div><br/><p>To claim your SpikySpaceFish UNITED (SSFU) , you need to connect with the MetaMask account owning the SpikySpaceFish (SSF).</p><br/>
    <p>You can claim SSFU up to 4 times the number of SSF you own.</p><br/>
    <p>The claim button will appear here at 4 PM EST.</p>
    </div>,

    maxTokenToMint: 3,
    showContract: false,
    phaseStatus:'beforesale' //switch to presale
    };



export const presale = {
    headline: 'CLAIM PHASE IS LIVE',
    subheadline:<div><p>Claim Until the 4th of Jan 2 PM EST </p>
      
    </div>,
    subheadline2: <div>* Please use MetaMask on computer </div>,
    maxTokenToMint: 0,
    showContract: false,
    phaseStatus:'presale' //switch to presale
    };

export const sale = {
      headline: 'PUBLIC SALE IS LIVE',
      subheadline: <p></p>,
      subheadline2: <div>* Please use MetaMask on computer </div>,
       maxTokenToMint: 10,
      showContract: false,
      phaseStatus:'sale' //switch to soldOut
      };

      export const aftersale = {
        headline: 'SOLD OUT !!',
        subheadline: <div>
                      <p>Thank you everybody. Check on Opensea</p>
                      </div>,
        subheadline2: <div></div>,
         maxTokenToMint: 0,
        showContract: true,
        phaseStatus:'aftersale', //switch to soldOut
        openseaURL:'https://opensea.io/collection/spikyspacefish-united'
        };



      export const mint = {
        presentationTxt:<p>One last hunt of 10,000 ssf has been authorised by the federation.<br/><br/>
                            Proof of their capture has been stored on the ethereum 
                            Blockchain as NFT and secured on the ipfs.</p>,
        sectionId:'mint',
        token2MintDefault:1,
        tokenPrice:0.02,
        fishes:fishes,
        alerts :alerts,
        specifs:aftersale,
        };



export const raffle = {
        colorBg: 'greenMorieBk',
        lightText: false,
        lightTextDesc: true,
        headline: 'Big Raffle of the first 200',
        subheadline: <p>Register to participate to a 10 days raffle and
           have a chance to get Mories at 0.02 ETH <br/>
           One draw every day at 12AM EST between october 23 and november 1rst<br/>
           Register once for all.<br/></p>,
         apologyLine :<p></p>,
        dividerColor:green[600],
        dividerAlpha:0.5,
        sectionId:'raffle',
        raffleCompleted:false,
        };


export const learn = {
          sectionId:'Learn',
          imageBlink:'images/F2_Focus.png',
          textImg1:'images/storysection/p1.png',          
          textImg2:'images/storysection/p2.png', 
          textImg3:'images/storysection/p3.png', 
          textImg4:'images/storysection/p4.png', 
          imgtransition:'images/F1_Focus.png',
          openseaURL:'https://opensea.io/collection/cryptomories',
          fishes:fishes,
          };

      
          export const spacedrive = {
            sectionId:'spacedrive',
             fishes:fishes,
};

export const team = {
  sectionId:'team',
   fishes:fishes,
   alerts:alerts,
};
