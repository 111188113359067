import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Kraken.css';






export default function Kraken() {
  
  
    


  return(
    <div className="kraken-wrapper">
      <h1>Hi NathanD</h1>
    </div>
  )
}
