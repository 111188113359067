import { useEffect, useState } from "react";
import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Container from "@mui/material/Container";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from "@mui/material";
import { alpha } from '@mui/material/styles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';



function ProvenanceSection({
  colorBg,
  lightText,
  sectionId,
  headline,
  subheadline,subheadline2,
  headerFairness,
  headerProvenance,
  dividerColor,dividerAlpha,
  fairNessText1,fairNessText2,
  provenanceText,
  linkList,
  imgbottom,
  maxTokenToMint,
  token2MintDefault,
  tokenPrice
}) {

  const MyDivider = styled(Divider)({
  
    fontSize: '20px',
    borderColor:alpha(dividerColor,dividerAlpha),
    color:dividerColor,
     '&:before': {
      borderColor:alpha(dividerColor,dividerAlpha),
    },
    '&:after': {
      borderColor:alpha(dividerColor,dividerAlpha),
    },
    marginBottom:'20px',
    marginTop:'20px'
  });
  
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }


  return (
    <>
      <Box  display="flex"  justifyContent="center" className={colorBg}   id={sectionId}>
      <Grid container  maxWidth="1300px"  className={colorBg}   sx={{paddingTop:5 }} >
          
      <Grid  container   direction="row"   justifyContent="space-between"    
      alignItems="flex-start" paddingLeft="20px">


          {/* Header  -------------------- */}
          <Grid item  xs={12} > 
              <div  className={lightText ? 'myheading' : 'myheading dark'}  >
                  {headline}
              </div>
          </Grid>

          <Grid item  xs={12}  >
              
              {/* subHeader text */}    
              <Box  className={lightText ? 'mysubheading2' : 'mynormaltext dark'}>
                  {subheadline}
              </Box>

          </Grid>
      
          {/* Fairnes block */}    
          <Grid item  xs={12}  > 
              <MyDivider textAlign="left" variant='middle'>{headerFairness}</MyDivider>
              
              {/* First part of text*/}    
              <Box  className={lightText ? 'mysubheading2' : 'mynormaltext dark'}   >
                {fairNessText1} 
              </Box>


              {/* First set of links */}    
              <Box display="flex" justifyContent="center" alignContent="center">
                  <List  className={lightText ? 'mynormaltext' : 'mynormaltext dark'} 
                  sx={{width:'350px'}}  display="flex"  alignItems="center">

                    {linkList.map((value) => {
                        if (value.id<3) {
                        return (
                      <ListItem  key={value.key} >
                            <ListItemButton  onClick={e => openInNewTab(value.link)} > 
                                  <ListItemIcon> 
                                      <img src={value.icon}   width="25px"  /> 
                                  </ListItemIcon>

                                  <ListItemText primary={value.fileName} />
                            </ListItemButton>
                      </ListItem>
                         );
                        }
                        })}
                  </List>
             </Box>

             {/* 2nd part of Fairnes text */}    
             <Box  className={lightText ? 'mysubheading2' : 'mynormaltext dark'}   >
                {fairNessText2} 
              </Box>

              {/* 2nd list of link */}    
              <Box display="flex" justifyContent="center" alignContent="center">
                  <List  className={lightText ? 'mynormaltext' : 'mynormaltext dark'} 
                  sx={{width:'350px'}}  display="flex"  alignItems="center">

                    {linkList.map((value) => {
                        if ((value.id==3) || (value.id==4)) {
                        return (
                      <ListItem  key={value.key} >
                            <ListItemButton  onClick={e => openInNewTab(value.link)} > 
                                  <ListItemIcon> 
                                      <img src={value.icon}   width="25px"  /> 
                                  </ListItemIcon>

                                  <ListItemText primary={value.fileName} />
                            </ListItemButton>
                      </ListItem>
                         );
                        }
                        })}
                  </List>
             </Box>


          </Grid> 
      

          {/* provenance block */}                  
          <Grid item  xs={12}  > 
          <MyDivider textAlign="left" variant='middle'>{headerProvenance}</MyDivider>
             

              <Box  className={lightText ? 'mysubheading2' : 'mynormaltext dark'}>
             {provenanceText}
              </Box>

              {/* 2nd list of link */}    
              <Box display="flex" justifyContent="center" alignContent="center">
                  <List  className={lightText ? 'mynormaltext' : 'mynormaltext dark'} 
                  sx={{width:'350px'}}  display="flex"  alignItems="center">

                    {linkList.map((value) => {
                        if ((value.id==5) || (value.id==6)) {
                        return (
                      <ListItem  key={value.key} >
                            <ListItemButton  onClick={e => openInNewTab(value.link)} > 
                                  <ListItemIcon> 
                                      <img src={value.icon}   width="25px"  /> 
                                  </ListItemIcon>

                                  <ListItemText primary={value.fileName} />
                            </ListItemButton>
                      </ListItem>
                         );
                        }
                        })}
                  </List>
             
             </Box>
             <MyDivider variant='middle'></MyDivider>             

          </Grid> 
      
         
      
      
      
      </Grid>{/*Container*/}






       
      </Grid>
      </Box> 

    </>
  );
}

export default ProvenanceSection;