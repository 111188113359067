import * as React from 'react';
import { styled } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { blue ,yellow} from '@mui/material/colors';
import{Chip} from '@mui/material';
import {shortWallet,openInNewTab} from '../../utils/myLib';
import { ThemeProvider } from '@mui/system';


const chipTheme = createTheme({
  typography: {
      fontFamily: 'Times'
     },
     '& .MuiChip-label':{
      fontFamily: 'Arial',
     },
  },
 )





const ChipContract = styled(Chip)({
      fontSize:'12px',
      fontWeight:600,
      height:'25px',
 });





export default function toto(props) {
  return (
<>
<ThemeProvider theme={chipTheme}>
<ChipContract label={'Smart Contract'}   component="a" 
                                                            href={"https://etherscan.io/address/" + props.contractAddress} 
                                                            rel="nofollow noopener" target="_blank" clickable  />
</ThemeProvider>
</>
  );
}

