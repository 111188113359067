import React from 'react';
import './LearnSection.css';
import {Box,Grid} from '@mui/material';
import { fontSize } from '@mui/system';
import {getFishImg} from '../../utils/myLib';


function LearnSection({
  sectionId,
  textImg1,  textImg2,  textImg3,textImg4,
  fishes,
}) {

  const imgStart ='';
 
const fishposition =[ 
  {fish:6,focus:false,back:false, width:"500px",left:"-600px",top:3000},//0
  {fish:2,focus:false,back:true, width:"550px",left:"-700px",top:4600},//1
  {fish:1,focus:false,back:false, width:"300px",left:"-500px",top:5800},//2
  {fish:3,focus:false,back:false, width:"500px",left:"100px",top:5500},//3
  {fish:7,focus:false,back:false, width:"500px",left:"2000px",top:4500},//4

];


const renderImgTop = () => {
  if (true) {
    return <img className='mint-top-img'  src='images/fishes/F8_150.png'  />;
  }
}

const renderImgBot = () => {
  if (true) {
    return <img className='mint-bot-img'  src={fishes[2]}  />;
  }
}


         {/* la petite fille qui tombe
       <Box className={"absolute"}  left="-2500px" top="3900px"  >
            <img src="images/deco/womenfalling.png" width="400px"></img>
        </Box>
        */} 



  return (
    
    <>

      <div class='learn-fishshoolcontainer'>
        { fishposition.map((f,i)=>{ return(

        <Box key={i} className={"absolute"}  left={f.left} top={f.top + "px"}  >
                  <img src={getFishImg(f.fish,f.focus,f.back)} width={f.width}></img>
                {/* <p style={{textAlign:"center", fontSize:'50px'}}>{"fish " + i} </p> */}
        </Box>
        )})}
        
      </div>




      <Box  display="flex"  justifyContent="center"    id={sectionId}  >

      <Grid container   className={'topcontainer'}   sx={{paddingTop:5 }}   >


      {/* Poisson fix tout petit  -------------------- 
      <Box className={"fixed"}>
        <img src={fishes[9]} width="35px" ></img>
      </Box>
      */}    
      

     
      
      <Grid  container   direction="column"   justifyContent="space-around"  rowSpacing={5} 
      alignItems="center" paddingLeft={{ xs: "0px", sm: "0px" }} mb="100px" mt="100px" >

        {/* Gros Fish */}

        <Grid Item className={'relativecontainer'}>
            <Box  className={'noselect'} >
              <img  className='learn-fishtop'  src={getFishImg(3,false,false)}/>
            </Box>
            <Box  className={'noselect'} >
              <img className='learn-fishbot'  src={getFishImg(2,false,false)}  />
            </Box>

        </Grid>

  
        {/* Bloc images */}
        <Grid item marginLeft={{ xs: "0px", sm: "0px" }} width={{ sm: "700px" }} mt="50x" >
              <Grid  container   direction="column"   justifyContent="center"    
                        alignItems="center" paddingLeft={{ xs: "0px", sm: "0px" }} >
                     <Grid item    > 
                        <Box   textAlign="center" className={'noselect'} >
                        <img src={textImg1} width="500px"  ></img>
                        </Box>
                    </Grid>
                    <Grid item    > 
                        <Box   textAlign="center" className={'noselect'}  >
                        <img src={textImg2} width="500px"   ></img>
                        </Box>
                    </Grid>
                    <Grid item    > 
                        <Box   textAlign="center" className={'noselect'} >
                        <img src={textImg3} width="500px"    ></img>
                        </Box>
                    </Grid>
                    <Grid item    > 
                        <Box   textAlign="center" className={'noselect'} >
                        <img src={textImg4} width="500px"    ></img>
                        </Box>
                    </Grid>
              </Grid>


        
        </Grid>





      
      </Grid>     
      



      </Grid>
      </Box>
    </>
  );
}

export default LearnSection;