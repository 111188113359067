import * as React from 'react';
import Button from '@mui/material/Button';
import MetamaskStatus from '../../utils/interact.js';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const greenBtn = green['A700']; 


const MintButton = styled(Button)(({ theme }) => ({
   variant:'contained',
   fontSize:25,
   borderRadius:'30px',
   width:'300px',
   height:'100px',
   color:'white',
   backgroundColor: greenBtn,
   '&:hover': {
     backgroundColor: 'tomato',
   },
 }));



const btnLabel = (props) => {


   if (props.raffleCompleted)  return "Raffle Over";
   if (props.registered)  return "You are ready!";
   switch (props.status) {
         case MetamaskStatus.ACCOUNT_CONNECTED :
         return "Register for the raffle";

         case MetamaskStatus.ACCOUNT_NOT_CONNECTED :
         return "Connect to Metamask";

         case MetamaskStatus.WRONG_NETWORK :
         return "Wrong Network";

         case MetamaskStatus.NO_METAMASK:
         return "Install Metamask";
   }


}


export default function RaffleRegisterButton(props) {
  return (
   
   <>
   
   
      <MintButton  onClick={props.onClick} > 
   
                  {btnLabel(props)}
           </MintButton>
   
  </>
   );
}

